<template>
  <gift-card-scanner-component />
</template>

<script>
import GiftCardScannerComponent from "@/components/GiftCard/giftCardScannerComponent";

export default {
  name: "GiftCardScannerView",
  components: { GiftCardScannerComponent }
};
</script>

<style scoped></style>
