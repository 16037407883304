<template>
  <v-container fluid class="fill-height pa-0">
    <qrcode-stream
      @detect="onDetect"
      :torch="true"
      class="rounded-lg"
      @init="onInit"
    >
    </qrcode-stream>

    <!--close this window -->
    <v-btn
      @click="quit"
      class="pa-0 px-0"
      id="close_btn"
      color="error lighten-2"
      rounded
    >
      <v-icon class="mx-0">
        mdi-close
      </v-icon>
    </v-btn>

    <!-- error dialog -->
    <v-dialog persistent v-model="is_error" max-width="600">
      <v-card class="text-center align-center">
        <v-card-title class="text-center">
          <v-avatar class="mx-auto" size="100" color="error">
            <v-icon color="white" x-large>
              mdi-alert-circle-outline
            </v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          {{ this.message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="quit" depressed text color="error">
            {{ $t("generic.lang_quit") }}
          </v-btn>

          <v-btn @click="tryAgain" depressed color="primary">
            {{ $t("generic.lang_tryAgain") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- success dialog -->
    <v-dialog persistent v-model="is_success" max-width="600">
      <v-card class="text-center align-center">
        <v-card-title class="text-center">
          <v-avatar class="mx-auto" size="100" color="primary">
            <v-icon color="white" x-large>
              mdi-check
            </v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <span v-html="this.message"></span>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="quit" depressed text color="error">
            {{ $t("generic.lang_no") }}
          </v-btn>

          <v-btn @click="applyGift" depressed color="primary">
            {{ $t("generic.lang_yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  name: "giftCardScannerComponent",
  data: () => ({
    scanned_card: "",
    card: {},
    is_error: false,
    is_success: false,
    message: ""
  }),
  methods: {
    quit() {
      this.$store.commit("Order/setGiftCard", {
        uuid: this.card.uuid,
        balance: this.card.balance
      });
      this.$router.push("/checkout");
    },
    tryAgain() {
      this.$router.go(0);
    },
    applyGift() {
      if (this.card)
        this.$store.commit("Order/setGiftCard", {
          uuid: this.card.uuid,
          balance: this.card.balance
        });

      this.quit();
    },
    async checkCard(decodedString) {
      this.scanned_card = decodedString;
      if (decodedString && decodedString.includes("GF$")) {
        const card = await this.$axios
          .get(ENDPOINTS.ERP.GIFTCARDS.GET(decodedString.split("GF$")[1]))
          .then(res => res.data)
          .catch(err => {
            if ("response" in err && err.response.status === 404) {
              throw this.$t("generic.lang_invalid_gift_card");
            }
          });
        this.card = card;
        if (card) {
          //check if already used but still have balance
          if (
            parseFloat(card.balance) > 0 &&
            card.usedAt !== null &&
            parseInt(card.usedAt) > 0
          ) {
            this.message =
              this.$t("generic.lang_gift_card_still_have_amount") +
              " " +
              this.$options.filters.currency(card.balance) +
              " , " +
              this.$t("generic.lang_do_you_really_want_use_the_gift_card");
            this.is_success = true;
            return;
          }

          //check if never used and have balance
          if (parseFloat(card.balance) > 0 && card.usedAt === null) {
            this.message =
              this.$t("generic.lang_gift_card_amount") +
              " " +
              this.$options.filters.currency(card.balance) +
              " , " +
              this.$t("generic.lang_do_you_really_want_use_the_gift_card");
            this.is_success = true;
            return;
          }

          //check if this card is empty
          if (parseFloat(card.balance) === 0) {
            this.message =
              this.$t("generic.lang_empty_gift_card") +
              " " +
              this.$options.filters.currency(card.balance) +
              " , " +
              this.$t("generic.lang_use_another_card");
            this.is_error = true;
            return;
          }
        }
      }
      throw this.$t("generic.lang_invalid_gift_card");
    },
    async onDetect(promise) {
      try {
        const {
          content // decoded String
        } = await promise;
        await this.checkCard(content);
      } catch (error) {
        this.is_error = true;
        this.message = error;
        this.$forceUpdate();
      }
    },
    async onInit(promise) {
      // show loading indicator

      try {
        const { capabilities } = await promise;

        console.log(capabilities);
        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          alert("NotAllowedError");
        } else if (error.name === "NotFoundError") {
          alert("NotFoundError");
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
          alert("NotSupportedError");
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
          alert("NotReadableError");
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
          alert("OverconstrainedError");
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
          alert("OverconstrainedError");
        }
      } finally {
        // hide loading indicator
      }
    }
  }
};
</script>

<style scoped>
#close_btn {
  position: absolute;
  min-width: 40px !important;
  min-height: 40px !important;
  top: 10px;
  right: 10px;
  z-index: 4000;
}

#scanned_card {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: auto;
  z-index: 4000;
  background: white;
}
</style>
